body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
.gallery-container {
  display: flex;
  justify-content: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  margin: 10px;
  width: calc(25% - 20px);
  text-align: center;
}

.gallery-item:hover {
  cursor: pointer;
}


.gallery-img {
  width: 100%;
  max-width: 300px;
  height: auto;
}
*/

body {
  background-color: #2f3136;
}

.App {
  margin: 0 50px; /* 左右各保留空白 */
}

img {
  width: 100%;
  height: 80%;
  max-height: 400px;
  /* object-fit: scale-down; */
  object-fit: cover;
}

/* 設置外層容器 */
.gallery-container {
  background-color: #36393f; /* 設置底色 */
  display: flex;
  justify-content: center;
}

/* 設置圖片容器 */
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 設置圖片項目 */
.gallery-item {
  position: relative; /* 需要設置 position，以便在子元素上使用 absolute */
  margin: 10px;
  width: calc(20% - 20px);
  text-align: center;
}

/* 當滑鼠移到圖片上時，顯示指針狀態 */
.gallery-item:hover {
  cursor: pointer;
}

/* 設置圖片
.gallery-img {
  width: 100%;
  max-width: 300px;
  height: auto;
  max-height: 30px;
} */

/* 設置圖片標題 */
.gallery-item-info {
  opacity: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  transition: opacity 0.3s;
}

.gallery-item:hover .gallery-item-info {
  opacity: 1;
}


.gallery-item-info ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gallery-item-info ul li {
  margin-bottom: 5px;
}

/* 設置濾鏡 */
.filter {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 50px;
}

.filter label {
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
}

.filter input[type="text"] {
  padding: 6px 10px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #36393f;
  font-size: 16px;
  width: 200px;
  box-sizing: border-box;
  margin-left: 20px;
}

.filter button {
  padding: 6px 10px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #36393f;
  font-size: 16px;
  /* width: 200px; */
  box-sizing: border-box;
  margin-left: 20px;
}

.filter input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 3px 1px #7289da;
}

.filter select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  box-shadow: none;
  outline: none;
  appearance: none; /* 重要 */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5'%3E%3Cpath d='M.77 0L0 .43 3.08 4.5c.05.05.11.08.17.08.06 0 .12-.03.17-.08L7.23.43 7.23 0 0 .01z' fill='%23626262'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px 5px;
  margin-left: 20px;
  width: 200px;
}

/* 假設在螢幕寬度小於600px時，過濾器元素的寬度變窄 */
@media (max-width: 600px) {
  h1 {
    font-size: 1rem;
  }
  
  .filter {
    flex-direction: column; /* 在小螢幕上改變方向，讓元素堆疊在一起 */
    align-items: flex-start; /* 修改對齊方式 */
    padding: 0 20px; /* 調整左右間距 */
  }

  .filter input[type="text"] {
    width: 100%; /* 將元素的寬度設為100%以適應較小的螢幕 */
    margin-bottom: 10px; /* 在元素之間添加一些間距 */
  }

  .filter button {
    width: auto;
    margin-left: calc(50% - 50px);
    margin-bottom: 10px; /* 在元素之間添加一些間距 */
  }

  .filter label,
  .filter select {
    width: 100%; /* 將元素的寬度設為100%以適應較小的螢幕 */
    margin-bottom: 10px; /* 在元素之間添加一些間距 */
  }

  .gallery-item {
    width: calc(30% - 20px);
    margin-bottom: 50px;
  }

  .gallery-item-info {
    font-size: 8px;
  }
}


h1 {
  font-family: 'Whitney', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #7289da;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  text-align: center;
}

/* hover 時的樣式 */
select:hover {
  border-color: #999;
}

/* focus 時的樣式 */
select:focus {
  border-color: #2b5aa5;
  box-shadow: 0 0 0 2px rgba(43, 90, 165, 0.2);
}

.resultNum {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
}

.footer {
  background-color: #2f3136;
  /* border-top: 2px solid red; */
  border: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  color: white;
  font-size: 15px;
  text-align: center;
}

.info {
  position: relative;
  height: 100%;
  /* border: 2px solid #006100; */
  border: none;
}

.btn_scrollToTop {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  background-color: #2f3136;
  border: none;
  font-size: 10px;
  color: white;
  margin-right: 20px;
}

.showResultNum {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 13px;
}
